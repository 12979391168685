const URLS = {
  home: "/",
  about: "/about-us",
  services: "/services",
  service: "/service",
  serviceBookKeeping: `/service/bookkeeping`,
  serviceManagement: "/service/managment-accounting",
  servicePayroll: "/service/payroll-process",
  serviceOutsource: "/service/outsource-audit",
  contact: "/contact-us",
};

export default URLS;
