import { Col, Divider, Grid, Row } from "antd";
import Title from "antd/es/typography/Title";
import { get, map } from "lodash";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Typewriter from "typewriter-effect";

const { useBreakpoint } = Grid;

const team = [
  {
    name: "Siddhartha Yagnik",
    key: "siddhartha-yagnik",
    image: "/teams/siddhartha-yagnik.png",
    description: {
      items: [
        "Siddhartha is Professional Accountant for more than 19 years. Since 2009 he has expertise on Outsourced Accounting and Financial Services Domain. Siddhartha has Studied ACCA from ACCA UK and CPA From AICPA. He is currently working for Accounting and Tax Domain of Financial Services and Planning to get his hands on AI areas for Accounting and Audit Services.",
        "Siddhartha is the main driving force of the organization in terms of employee empowerment and working as team. He is Head of Operations of Financial Consulting Division of the Company and also CEO and Managing Director of the La Comptabilité Le Group.",
      ],
    },
  },
  {
    name: "Foram Yagnik",
    key: "foram-yagnik",
    image: "/teams/foram-yagnik.png",
    description: {
      items: [
        "Foram Yagnik is a professional Pharmacist from India and She is Masters in pharmacy with Specialization in pharmaceutics. She has total Experience of the field for 12 Years and she is Handling Our Pharmacy and Pharmaceutical and API Export Lines of Business of our group company La Comptabilité Business Services LLP.",
        "Foram is the Energy Force of Our Organization. She is working as Director in La Comptabilité India Private Limited.",
      ],
    },
  },
];

const About = () => {
  const { xs, lg } = useBreakpoint();
  return (
    <div>
      <Helmet>
        <title>
          About Us | La Camptabilite | Bookkeeping | Management Accounting |
          Payroll Service | Outsourced Audit Services | United States | United
          Kingdom | Europe | Middle East
        </title>
        <meta
          name="description"
          content="La Comptabilité is a professional accounting and bookkeeping service provider that caters to businesses of all sizes. Our team of experts is dedicated to helping you manage your finances efficiently and effectively. We offer a wide range of services including bookkeeping, tax preparation, payroll processing, and financial statement preparation. With our help, you can focus on growing your business while we take care of the numbers. Contact us today to learn more about how we can assist you in achieving your financial goals."
        />
        <meta
          property="og:description"
          content="La Comptabilité is a professional accounting and bookkeeping service provider that caters to businesses of all sizes. Our team of experts is dedicated to helping you manage your finances efficiently and effectively. We offer a wide range of services including bookkeeping, tax preparation, payroll processing, and financial statement preparation. With our help, you can focus on growing your business while we take care of the numbers. Contact us today to learn more about how we can assist you in achieving your financial goals."
        />
        <meta
          property="og:title"
          content="About Us | La Camptabilite | Bookkeeping | Management Accounting | Payroll
      Service | Outsourced Audit Services | United States | United Kingdom |
      Europe | Middle East"
        />
      </Helmet>
      <figure className="image">
        <img src="/about-splash.jpg" alt="404 - Not Found" />
      </figure>
      <div className="container my-6 p-3 py-4">
        <div
          className="has-text-centered"
          data-aos="fade-up"
          data-aos-duration={1000}
        >
          <Title level={1} className="m-0">
            Your trusted
          </Title>
          <Title level={1} className="m-0 has-text-blue1">
            <Typewriter
              options={{ cursor: "" }}
              onInit={(type) => {
                type
                  .typeString("Accounting")
                  .pauseFor(2500)
                  .deleteAll()
                  .typeString("Bookkeeping")
                  .pauseFor(2500)
                  .deleteAll()
                  .typeString("Outsourcing Audit")
                  .pauseFor(2500)
                  .deleteAll()
                  .typeString("Payroll")
                  .pauseFor(2500)
                  .deleteAll()
                  .start();
              }}
            />
          </Title>
          <Title level={1} className="m-0">
            partner
          </Title>
          <Title
            level={3}
            className="has-text-weight-normal mb-0"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            La Camptabilite accounting services are designed to help you
          </Title>
          <Title
            level={3}
            className="has-text-weight-normal mt-0"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            make informed financial decisions.
          </Title>
        </div>
      </div>
      <div className="container my-6 p-3">
        <Title
          level={2}
          className="has-text-centered has-text-blue1"
          data-aos="fade-up"
          data-aos-duration={1000}
        >
          Our Team
        </Title>
        <p
          className="has-text-centered"
          data-aos="fade-up"
          data-aos-duration={1000}
        >
          Our leadership team brings years of experience financial experience in
          <br />
          financial analysis, bookkeeping, and more.
        </p>
        <div className="my-6">
          {map(team, (member, index) => (
            <Fragment key={member.key}>
              <div data-aos="fade-up" data-aos-duration={1000}>
                <Row>
                  {xs && (
                    <Col
                      span={24}
                      className="is-flex is-align-items-center is-justify-content-center"
                    >
                      <div
                        style={{
                          border: "1px solid #222",
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                      >
                        <figure className="image">
                          <img src={member.image} alt="404 - Not Found" />
                        </figure>
                      </div>
                    </Col>
                  )}
                  {lg && index % 2 === 0 && (
                    <Col
                      span={8}
                      className="is-flex is-align-items-center is-justify-content-center"
                    >
                      <div
                        style={{
                          border: "1px solid #222",
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                      >
                        <figure className="image" style={{ width: 200 }}>
                          <img src={member.image} alt="404 - Not Found" />
                        </figure>
                      </div>
                    </Col>
                  )}
                  <Col
                    xs={24}
                    lg={16}
                    className="is-flex is-align-items-center"
                  >
                    <div className="my-2">
                      <Title level={5}>{member.name}</Title>
                      {map(get(member, "description.items", []), (item, i) => (
                        <p
                          className="has-text-justified my-2"
                          key={`${index}-${i}`}
                        >
                          {item}
                        </p>
                      ))}
                    </div>
                  </Col>
                  {lg && index % 2 === 1 && (
                    <Col
                      span={8}
                      className="is-flex is-align-items-center is-justify-content-center"
                    >
                      <div
                        style={{
                          border: "1px solid #222",
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                      >
                        <figure className="image" style={{ width: 200 }}>
                          <img src={member.image} alt="404 - Not Found" />
                        </figure>
                      </div>
                    </Col>
                  )}
                </Row>
                {index !== team.length - 1 && <Divider />}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
