import {
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Grid,
  Input,
  Row,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import Title from "antd/es/typography/Title";
import { capitalize, forEach, map } from "lodash";
import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";

const { useBreakpoint } = Grid;

export const contactItems = [
  {
    key: "address",
    items: [
      {
        link: "https://goo.gl/maps/FVabYeGjyjej7mFbA",
        label: (
          <>
            604, Pehel Lakeview,
            <br />
            Opp. Anusthan Homes,
            <br />
            Ratna Paradise Service Road, Khoraj,
            <br />
            Gandhinagar, Gujarat 38242
          </>
        ),
      },
    ],
    icon: <EnvironmentOutlined style={{ fontSize: 25 }} />,
    title: "Visit Us",
  },
  {
    key: "email",
    items: [
      {
        link: "mailto:siddhartha@lacomipl.com",
        label: "siddhartha@lacomipl.com",
      },
    ],
    icon: <MailOutlined style={{ fontSize: 25 }} />,
    title: "Email Us",
  },
  {
    key: "phone",
    items: [{ link: "tel:+919601354849", label: "(+91) 96013 54849" }],
    icon: <PhoneOutlined style={{ fontSize: 25 }} />,
    title: "Call Us",
  },
];

const Contact = () => {
  const { xl } = useBreakpoint();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = (formData) => {
    setLoading(true);
    let body = "<h4>La Comptabilité Website Lead Details</h4>";
    forEach(Object.keys(formData), (key) => {
      if (formData[key])
        body += `<p><span style="fontWeight: 500">${capitalize(key)}</span> : ${
          formData[key]
        }</p>`;
    });
    fetch("https://api.diginull.in/emails/contact-us-email", {
      method: "POST",
      body: JSON.stringify({
        body: body,
        bodyType: "html",
        to: ["siddhartha@lacomipl.com"],
      }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          notification.success({
            message:
              "We have captured your response, our concern team will contact you soon!",
          });
          form.resetFields();
        } else {
          notification.warning({
            message: "Error while recording your response, try again!",
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "Error while connection server, try again after sometime!",
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <Helmet>
        <title>
          Contact Us | La Camptabilite | Bookkeeping | Management Accounting |
          Payroll Service | Outsourced Audit Services | United States | United
          Kingdom | Europe | Middle East
        </title>
        <meta
          name="description"
          content="The La Comptabilité website offers expert accounting and bookkeeping services. Contact us today to receive top-notch assistance and ensure your financial needs are met. Our team of professionals are here to help you manage your finances and provide personalized solutions tailored to your unique situation. Don't hesitate to reach out and let us help you achieve financial success."
        />
        <meta
          property="og:description"
          content="The La Comptabilité website offers expert accounting and bookkeeping services. Contact us today to receive top-notch assistance and ensure your financial needs are met. Our team of professionals are here to help you manage your finances and provide personalized solutions tailored to your unique situation. Don't hesitate to reach out and let us help you achieve financial success."
        />
        <meta
          property="og:title"
          content="Contact Us | La Camptabilite | Bookkeeping | Management Accounting | Payroll
      Service | Outsourced Audit Services | United States | United Kingdom |
      Europe | Middle East"
        />
      </Helmet>
      <figure className="image">
        <img src="/contact-splash.jpg" alt="44 - Not Found" />
      </figure>
      <div className="container my-6">
        <Row>
          <Col xs={24} xl={3}></Col>
          <Col
            xs={24}
            lg={12}
            xl={9}
            className="p-3 is-flex is-align-items-center"
          >
            <div>
              <Title data-aos="fade-up" data-aos-duration={1000}>
                Get in touch with our team
              </Title>
              <Title
                data-aos="fade-up"
                data-aos-duration={1000}
                level={4}
                className="has-text-weight-normal"
              >
                Ready to learn more about our services or schedule a
                consultation? Our team is ready to answer your questions and
                help you.
              </Title>
              <div className="my-6"></div>
              <Title
                data-aos="fade-up"
                data-aos-duration={1000}
                level={4}
                className="mb-2"
              >
                Wanna talk with us?
              </Title>
              <Title
                data-aos="fade-up"
                data-aos-duration={1000}
                level={5}
                className="has-text-weight-normal mb-1"
              >
                <PhoneOutlined
                  className="mr-3"
                  style={{ transform: "rotate(90deg)" }}
                />
                +91 96013 54849
              </Title>
              <Title
                data-aos="fade-up"
                data-aos-duration={1000}
                level={5}
                className="has-text-weight-normal mt-0"
              >
                <MailOutlined className="mr-3" />
                siddhartha@lacomipl.com
              </Title>
            </div>
          </Col>
          <Col xs={24} lg={12} xl={9} className="p-3">
            <Card data-aos="fade-up" data-aos-duration={1000} className="card">
              <Form layout="vertical" form={form} onFinish={onFinish}>
                <Form.Item name="name" label="Name">
                  <Input placeholder="Enter your name" />
                </Form.Item>
                <Row>
                  <Col xs={24} lg={12} className={`${xl ? "pr-2" : ""}`}>
                    <Form.Item name="email" label="Email">
                      <Input placeholder="Enter your email" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12} className={`${xl ? "pl-2" : ""}`}>
                    <Form.Item name="phone" label="Phone">
                      <Input placeholder="Enter your phone number " />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="description" label="Description">
                  <Input.TextArea
                    placeholder="Describe your inquiry here"
                    rows={4}
                    style={{ resize: "none" }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    style={{ width: "100%" }}
                    loading={loading}
                  >
                    Send Message
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
      <iframe
        title="La Camptabilite"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3668.934353017502!2d72.54951797582069!3d23.13607467909438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e83ffc0f90909%3A0xd5786852d26d1d7c!2sLa%20Comptabilit%C3%A9%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1687214195224!5m2!1sen!2sin"
        width="100%"
        height="450"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <div className="container my-6">
        <Row>
          {map(contactItems, (item, index) => (
            <Fragment key={item.key}>
              <Col xs={24} lg={6} className="p-3">
                <div data-aos="fade-up" data-aos-duration={1000}>
                  <Row>
                    <Col
                      span={3}
                      className="pr-2 is-flex is-justify-content-flex-end"
                    >
                      {item.icon}
                    </Col>
                    <Col span={21}>
                      <Title level={4}>{item.title}</Title>
                      <div>
                        {map(item.items, (i, key) => (
                          <p
                            key={`${item.key}/${key}`}
                            onClick={() => {
                              window.open(i.link, "_blank");
                            }}
                          >
                            {i.label}
                          </p>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              {index !== contactItems.length - 1 && (
                <>
                  <Col key={item.key} xs={0} lg={3} className="py-4">
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                  <Col key={item.key} xs={24} lg={0} className="px-4">
                    <Divider />
                  </Col>
                </>
              )}
            </Fragment>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Contact;
