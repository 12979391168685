import React, { useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFooter } from "../../actions/ui";
import { get, map } from "lodash";
import {
  CopyrightOutlined,
  BgColorsOutlined,
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
} from "@ant-design/icons";
import { useLayout } from "../hooks/layout";
import { Button, Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import URLS from "../../constants/nav";
import { Link } from "react-router-dom";

const usefulLinks = [
  { key: URLS.home, label: "Home" },
  { key: URLS.services, label: "Services" },
  { key: URLS.about, label: "About us" },
  { key: URLS.contact, label: "Contact us" },
];

const services = [
  { key: URLS.serviceBookKeeping, label: "Bookkeeping" },
  { key: URLS.serviceManagement, label: "Account Management" },
  { key: URLS.servicePayroll, label: "Payroll" },
  { key: URLS.serviceOutsource, label: "Outsource Audit" },
];

export const contactItems = [
  {
    key: "address",
    items: [
      {
        link: "https://goo.gl/maps/FVabYeGjyjej7mFbA",
        label: (
          <>
            604, Pehel Lakeview,
            <br />
            Opp. Anusthan Homes,
            <br />
            Ratna Paradise Service Road, Khoraj,
            <br />
            Gandhinagar, Gujarat 38242
          </>
        ),
      },
    ],
  },
  {
    key: "email",
    items: [
      {
        link: "mailto:siddhartha@lacomipl.com",
        label: "siddhartha@lacomipl.com",
      },
    ],
  },
  {
    key: "phone",
    items: [{ link: "tel:+919601354849", label: "(+91) 96013 54849" }],
  },
];

const socialMediaIcons = [
  {
    key: "facebook",
    label: "Facebook",
    icon: <FacebookFilled style={{ fontSize: 25 }} />,
  },
  {
    key: "instagram",
    label: "Instagram",
    icon: <InstagramFilled style={{ fontSize: 25 }} />,
  },
  {
    key: "https://www.linkedin.com/company/lacomindiapvtltd",
    label: "LinkedIn",
    icon: <LinkedinFilled style={{ fontSize: 25 }} />,
  },
];

const Footer = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { mobile } = useLayout();
  const width = useSelector(state=>state.ui.Width)
  useLayoutEffect(() => {
    dispatch(updateFooter(get(ref, "current.clientHeight")));
  });

  return (
    <div ref={ref} className="app-footer has-background-white">
      <div className="container py-6 px-2">
        <Row>
          <Col xs={24} md={12} lg={8} className="p-3">
            <Title level={5}>About Company</Title>
            <p className="has-text-justified">
              La Comptabilité India Private Limited or LCIPL is part of La
              Comptabilité Lé Group into businesses of Full Service Financial,
              Legal and IT consulting, Pharmacy and Pharmaceuticals, Edcom and
              Exports. La Comptabilité India Private Limited is Multicore Full
              Service Financial and IT Consulting Services Organization with
              strong base of Professionalism and Expertise.
            </p>
            <div className="my-2 is-flex is-flex-wrap-wrap">
              {map(socialMediaIcons, (social) => (
                <a
                  href={social.key}
                  rel="noreferrer noopener"
                  target="_blank"
                  className="mr-2"
                  key={social.key}
                >
                  <Button
                    type="text"
                    className="social-button"
                    icon={social.icon}
                    shape="circle"
                  />
                </a>
              ))}
            </div>
          </Col>
          <Col xs={0} md={0} lg={1}></Col>
          <Col xs={9} md={12} lg={3} className="p-3">
            <Title level={5} style={width < 334 ? {fontSize: 13} : {}}>Useful Links</Title>
            <div>
              {map(usefulLinks, (link) => (
                <div key={link.key}>
                  <Link style={width < 334 ? {fontSize: 13} : {}} className="footer-link" to={link.key} key={link.key}>
                    {link.label}
                  </Link>
                </div>
              ))}
            </div>
          </Col>
          <Col xs={15} md={12} lg={4} className="p-3">
            <Title level={5} style={width < 334 ? {fontSize: 13} : {}}>Services</Title>
            <div>
              {map(services, (link) => (
                <div key={link.key}>
                  <Link
                  style={width < 334 ? {fontSize: 13} : {}}
                    className="footer-link"
                    to={`${link.key}`}
                    key={link.key}
                  >
                    {link.label}
                  </Link>
                </div>
              ))}
            </div>
          </Col>
          <Col xs={0} md={0} lg={1}></Col>
          <Col xs={24} md={12} lg={7} className="p-3">
            <Title level={5} style={width < 334 ? {fontSize: 14} : {}}>Contact Info</Title>
            <div>
              {map(contactItems, (item) => (
                <div className="my-2">
                  {map(item.items, (i, key) => (
                    <p key={`${item.key}/${key}`}
                    >
                      <a
                        style={width < 334 ? {fontSize: 13} : {fontSize: 16}}
                        className="has-text-black"
                        href={i.link}
                        target="_blank"
                        rel="noreferrer noopener"
                        key={i.link}
                      >
                        {i.label}
                      </a>
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
      <div className="is-flex is-justify-content-space-between is-flex-wrap-wrap p-3 has-background-blue1">
        <p
          className={`has-text-white ${mobile ? "has-text-centered" : ""}`}
          style={{ width: mobile ? "100%" : "auto" }}
        >
          <CopyrightOutlined /> La Camptabilite @ 2023, All Rights Reserved
        </p>
        <p
          className={`has-text-white ${mobile ? "has-text-centered" : ""}`}
          style={{ width: mobile ? "100%" : "auto" }}
        >
          <BgColorsOutlined /> Designed by{" "}
          <a
            className="has-text-white"
            href="https://diginull.in"
            target="_blank"
            rel="noreferrer noopner"
          >
            DigiNull
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
