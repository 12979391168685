import URLS from "./nav";

const services = [
  {
    key: URLS.serviceBookKeeping,
    title: "Bookkeeping",
    splash: "/bookkeeping-spalsh.jpg",
    meta: "La Comptabilité provides bookkeeping services to clients around the world. Our team of experts is dedicated to ensuring accurate and timely financial records for your business. Trust us to handle your bookkeeping needs so you can focus on growing your business. Contact us today to learn more about our services.",
    intro: (
      <>
        La Comptabilité provides bookkeeping services to
        <br /> help businesses maintain accurate financial records.
      </>
    ),
    descriptions: [
      "LCIPL offers bookkeeping services for CPA Clients and Business Houses in partnership with Local CPA/ACCA/ACA Clientele.",
      "Our team of Experts have in-depth knowledge in US GAAP accounting and ASB Auditing Standards with deep penetration in Bookkeeping, Accounting, Taxation and Audit Services.",
      "Our Experts are Highly Qualified in IFRS, IAASB and Other Global GAAP Practices and Country Specific GAAPs.",
      "We use the Most Recent technology to manage the bookkeeping services for our clients. We have an incredible list with Deep Assessment of popular virtual bookkeeping software systems Outsourcing Accounting backed by the major software such as QuickBooks, Xero, and Zoho Books is our forte. Our Bookkeeping Staff is well-versed in managing the various cloud-based accounting and virtual bookkeeping software.",
      "For Client Management we are using Zoho ERP which is best in class and latest software.",
      "Another important aspect of our services is to ensure utmost priority given to client confidential business information stays intact. Every business owner faces stress due to the risk of tampering of critical business details. At LCIPL, we have local FTP servers for each countries along with a separate team of IT and Data Security professionals with best in class Services in all the aspects of keeping the privacy and security of the data during the entire flow of the delivery of your bookkeeping service.",
      "We also offer you a quick turnaround time for each task and scalability of your team during peak business season. With our bookkeeping services, you can leave all your routine and time-consuming transactional tasks to us to move to the next level.",
    ],
    services: [
      {
        title: "Account Payable Services",
        description:
          "We will process your Invoices, Expenses and Bills and reconcile it with Supplier and Vendor Statements along with payment processing. We will help you control your expense with our inhouse AR KPI and Cash Flow Statements with budgeting.",
      },
      {
        title: "Account Receivable Services",
        description:
          "We can help you to settle Payments of your Clients and Customers. We will create Statements and Estimates on your behalf using White Labeling Services.",
      },
      {
        title: "Bank And Credit Card Processing",
        description:
          "We record all transactions from your bank and process bank statement reconciliation of all entries, match vendor invoice with your ledger, journal entries, sequencing of checks, and review of checks deposited, issued, cleared.",
      },
      {
        title: "Credit Card Management",
        description:
          "We record each transaction whenever you use a credit card to help you manage expenses and understand where you spent your precious funds. Our team offers credit card reconciliation with your statement records",
      },
    ],
  },
  {
    key: URLS.serviceManagement,
    splash: "/management-accounting-splash.jpg",
    title: "Management Accounting",
    meta: "La Comptabilité offers top-notch Management Accounting services globally. Our team of experts provides comprehensive solutions to help businesses make informed decisions and improve their financial performance. Trust us to provide accurate and timely financial reports, analysis, and insights that will help you grow your business. Contact us today to learn more about how we can support your business needs.",
    descriptions: [
      "LCIPL offers strong ability in managing the most complex challenges related to finance and accounting. We are well versed in the latest developments in Financial and Accounting (F&A) regulations, which are highly dynamic.",
      "Access to exceptional, economical, and professional service providers, in-house or locally for small and mid-size accounting firms. We are becoming the helping hand for every accounting firm facing any trouble managing their accounting operations.",
      "The management team at LCIPL includes skilled CPAs, CAs, and other financial experts who have a penchant for perfection in the services we deploy. They have the adeptness and have the exposure to resolve various nuances of accountancy. We have trained our entire accounting workforce to deliver the best practices in accuracy and quality in professional supervision services. Emerging CPA and Accounting Firms can use our team members' experience and ability by availing our distinguished outsourced accounting services.",
    ],
    services: [
      { title: "Cashflow Management" },
      { title: "Tax Planning & Financial Analysis" },
      { title: "Revenue Recognition and Adjustments" },
      { title: "Cloud Software Integration" },
      { title: "Management Accounting" },
      {
        title:
          "Conducting Detailed Comparisons and Research for Budgets or Forecasts",
      },
    ],
    intro: (
      <>
        La Camptabilite provides comprehensive management accounting to
        <br />
        support businesses in making informed financial decisions
      </>
    ),
  },
  {
    key: URLS.servicePayroll,
    splash: "/payroll-splash.jpg",
    title: "Payroll Processing",
    meta: "La Comptabilité offers global payroll services, ensuring that businesses can efficiently manage their employees' salaries and related taxes. Our team of experts is dedicated to providing accurate and timely payroll solutions, no matter where your business operates. Trust La Comptabilité to handle your payroll needs, so you can focus on growing your business. Contact us today to learn more about our payroll services.",
    intro: (
      <>
        La Comptabilité specializes in delivering end-to-end
        <br />
        payroll services for businesses of all magnitudes.
      </>
    ),
    descriptions: [
      "LCIPL offers end-to-end payroll services for accounting firms. We supply the capability to manage complex payroll ecosystems. With experience spanning across different industries and geographies, we bring flexibility, transparency, and insight throughout the entire payroll process. We deliver the best payroll solutions that address the complete gamut of enterprise-payroll needs.",
    ],
    services: [
      {
        title: "Payroll Processing",
        description:
          "Calculate Payroll and obligation for each employee and supply them Employee management Reports.",
      },
      {
        title: "Payroll Planning",
        description:
          "We Plan you Payroll obligations according to your tax system and provide you robust Tax Planning.",
      },
      {
        title: "Payroll Accounting",
        description: "We will lodge Federal and State payroll Tax returns.",
      },
      {
        title: "Payroll Tax Reporting",
        description:
          "We manage Payroll tax calculations payments and filings with detail W2 and W4 Reporting.",
      },
    ],
  },
  {
    key: URLS.serviceOutsource,
    splash: "/pre-audit-splash.jpg",
    title: "Outsourced Audit Services",
    meta: "La Comptabilité offers Outsourced Audit Services globally. Our team of experts provides comprehensive audit services to meet your business needs. Trust us to help you improve your financial reporting and reduce risk. Contact us today to learn more about our services and how we can assist your organization.",
    intro: (
      <>
        La Camptabilite offers an outsourced audit service to
        <br />
        support international clients in preparing for official audits.
      </>
    ),
    descriptions: [
      "From audit planning to completion, LCIPL offer top-quality audit support services to accounting firms. LCIPL assist accounting firms in scaling up and optimizing their audit processes besides substantial cost savings. Our teams of qualified and experienced accounting professionals prepare audit files and help accounting firms eliminate errors and improve overall audit efficiency alongside increasing profitability.",
      "Auditing is a highly responsible and analytical job for auditors – to vouch for the authenticity, relevance and quality of a client’s financial statements on the basis of appropriate evidences. In short, auditing is high-cost, time-consuming and often involves high stakes for accountants and their clients.",
    ],
    services: [
      {
        title: "Audit",
        description: (
          <>
            The audit is unbiased and objective examination of the financial
            statements of an organization, irrespective of its size, nature, and
            legal structure, with the aim of expressing the opinion on the
            financial statements through an audit report.
            <br />
            <br />
            The auditor evaluates whether the reports prepared by the entity
            conforms to the financial reporting framework. The primary objective
            of audit is to determine whether the financial statement represents
            true and fair view, and the secondary objective is to detect if
            there are any errors or frauds, in the financial statements prepared
            by client.
            <br />
            <br />
            There can be two types of audits: Internal audit and external audit,
            wherein the internal audit is performed by the employees or head of
            the department of the organization, whereas the external audit is
            performed by external auditor.
          </>
        ),
      },
      {
        title: "Review",
        description: (
          <>
            A review provides limited assurance of financial statements, which
            is sufficient in many instances for a company and its creditors.
            Thus, it is a sort of mid-level assessment. In finer terms, it
            states that the financial statements are free from material
            misstatement.
            <br />
            <br />
            Our team performs all the required inquiry and analytical procedures
            to suggest if any modifications are required in the financial
            statements. by client.
          </>
        ),
      },
      {
        title: "Compilation",
        description: (
          <>
            A compilation is the presentation of financial statements by outside
            accountant without any opinion. It is an internal report
            representing the financial condition of a company.
            <br />
            <br />
            Our compilation services are executed in accordance with the
            American Institute of Certified Public Accountants and other Audit
            Regulation boards of the World.
          </>
        ),
      },
    ],
  },
];

export default services;
