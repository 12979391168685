import React from "react";
import { Link } from "react-router-dom";
import URLS from "../../constants/nav";
import { Result } from "antd";

const NotFound = () => {
  return (
    <div
      style={{ height: "inherit" }}
      className="is-flex is-justify-content-center is-align-items-center py-6"
    >
      <div className="has-text-centered">
        <Result
          status={404}
          title={
            <p className="title is-5 m-0 my-3">
              Ohh Snap! The page you are looking for does not exist!
            </p>
          }
          subTitle={
            <p className="title is-6 m-0">
              Please check the URL you are visiting, else you can go to home by
              clicking <Link to={URLS.home}>Here</Link>
            </p>
          }
        />
      </div>
    </div>
  );
};

export default NotFound;
