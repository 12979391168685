import { uiRedux } from "../constants/redux";

export const updateHeader = (size) => ({
  type: uiRedux.updateHeader,
  data: size,
});

export const updateFooter = (size) => ({
  type: uiRedux.updateFooter,
  data: size,
});

export const updateScroll = () => ({
  type: uiRedux.updateScroll,
  data: window.scrollY,
});

export const updateWidth = () => ({
  type: uiRedux.updateWidth,
  data: window.innerWidth,
});

export const updateHeight = () => ({
  type: uiRedux.updateHeight,
  data: window.innerHeight,
});

export const updateScrolled = (data) => ({
  type: uiRedux.updateScrolled,
  data,
});
