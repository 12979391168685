import { forEach, get, map } from "lodash";
import React from "react";
import { useNavigate, useParams } from "react-router";
import services from "../../constants/services";
import NotFound from "../extra/404";
import URLS from "../../constants/nav";
import { useLayout } from "../hooks/layout";
import Title from "antd/es/typography/Title";
import { Button, Col, Divider, Grid, Row } from "antd";
import { Helmet } from "react-helmet";

const { useBreakpoint } = Grid;

const getActiveTab = (key) => {
  let tab = null;
  forEach(services, (service) => {
    if (service.key === `${URLS.service}/${key}`) tab = service;
  });
  return tab;
};

const ServiceDetail = () => {
  const { mobile, tablet, pc } = useLayout();
  const { service } = useParams();

  const active = getActiveTab(service);

  const { lg } = useBreakpoint();
  const navigate = useNavigate();

  return !active ? (
    <NotFound />
  ) : (
    <div>
      <Helmet>
        <title>
          {`${get(
            active,
            "title"
          )} - Services | La Camptabilite | Bookkeeping | Management Accounting |
          Payroll Service | Outsourced Audit Services | United States | United
          Kingdom | Europe | Middle East`}
        </title>
        <meta name="description" content={active.meta} />
        <meta property="og:description" content={active.meta} />
        <meta
          property="og:title"
          content={`${get(
            active,
            "title"
          )} - Services | La Camptabilite | Bookkeeping | Management Accounting |
          Payroll Service | Outsourced Audit Services | United States | United
          Kingdom | Europe | Middle East`}
        />
      </Helmet>
      <div>
        <div
          className="splash"
          style={{
            backgroundImage: `url('${active.splash}')`,
            height: mobile ? 250 : tablet ? 350 : pc ? 450 : 600,
          }}
        >
          <div className="splash-wrapper p-3">
            <div>
              <Title
                level={lg ? 1 : 2}
                className="has-text-white has-text-centered"
                data-aos="fade-up"
                data-aos-duartion={1000}
              >
                {get(active, "title")}
              </Title>
              <Title
                level={4}
                className="has-text-centered has-text-white has-text-weight-normal"
                data-aos="fade-up"
                data-aos-delay={500}
                data-aos-duartion={1000}
              >
                {active.intro}
              </Title>
            </div>
          </div>
        </div>
      </div>
      <div className="has-background-blue2 py-6">
        <div className="container px-3">
          <Row>
            {!lg ? (
              <Col span={24} className="p-3">
                <Title
                  level={1}
                  className="m-0"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                >
                  Our Process
                </Title>
                <div
                  style={{ width: 75 }}
                  data-aos="zoom-in"
                  data-aos-duration={1000}
                >
                  <Divider className="has-background-grey mt-2 mb-5" />
                </div>
                <p data-aos="fade-right" data-aos-duration={1000}>
                  Our Methods are designed to be seamless and efficient,
                  allowing you to focus on your business while we take care of
                  your financial needs.
                </p>
                <Button
                  data-aos="zoom-out"
                  data-aos-duration={1000}
                  type="primary"
                  className="mt-5 mb-3"
                  onClick={() => navigate(URLS.contact)}
                >
                  Get a Consultation
                </Button>
              </Col>
            ) : null}
            <Col xs={24} lg={14} className="p-3">
              {map(active.descriptions, (description, key) => (
                <div
                  key={(key = { key })}
                  data-aos="fade-right"
                  data-aos-duration={1000}
                >
                  <div className="is-flex my-4">
                    <Title level={5} className="m-0">
                      {key + 1}
                    </Title>
                    <Title level={5} className="m-0 mr-3">
                      .
                    </Title>
                    <Title
                      level={5}
                      className="m-0 has-text-weight-normal has-text-justified"
                    >
                      {description}
                    </Title>
                  </div>
                  {key !== active.descriptions.length - 1 && (
                    <div style={{ width: 150 }}>
                      <Divider />
                    </div>
                  )}
                </div>
              ))}
            </Col>
            <Col xs={0} lg={1}></Col>
            {lg ? (
              <Col span={7} className="p-3">
                <Title
                  data-aos="fade-up"
                  data-aos-duration={1000}
                  level={1}
                  className="m-0"
                  style={{ fontSize: 50 }}
                >
                  Our Process
                </Title>
                <div
                  style={{ width: 100 }}
                  data-aos="zoom-in"
                  data-aos-duration={1000}
                >
                  <Divider className="has-background-grey mt-2 mb-5" />
                </div>
                <Title
                  data-aos="fade-right"
                  data-aos-duration={1000}
                  level={4}
                  className="mt-0 mb-4 has-text-weight-normal"
                >
                  Our Methods are designed to be seamless and efficient,
                  allowing you to focus on your business while we take care of
                  your financial needs.
                </Title>
                <Button
                  data-aos="zoom-out"
                  data-aos-duration={1000}
                  type="primary"
                  className="my-5"
                  onClick={() => navigate(URLS.contact)}
                  size="large"
                >
                  Get a Consultation
                </Button>
              </Col>
            ) : null}
          </Row>
        </div>
      </div>
      <div className="has-background-blue3 py-6">
        <div className="container px-3">
          <Row>
            {!lg ? (
              <Col span={24} className="p-3">
                <Title
                  level={1}
                  className="m-0"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                >
                  Simplify Your
                  <br />
                  {active.title}
                </Title>
                <div
                  style={{ width: 75 }}
                  data-aos="zoom-in"
                  data-aos-duration={1000}
                >
                  <Divider className="has-background-grey mt-2 mb-5" />
                </div>
                <p data-aos="fade-right" data-aos-duration={1000}>
                  We use the latest technology to streamline the process,
                  ensuring accuracy and timelines every step of the way.
                </p>
                <Button
                  data-aos="zoom-out"
                  data-aos-duration={1000}
                  type="primary"
                  className="mt-5 mb-3"
                  onClick={() => navigate(URLS.contact)}
                >
                  Get a Callback
                </Button>
              </Col>
            ) : null}
            <Col xs={24} lg={14} className="p-3">
              {map(active.services, (service, key) => (
                <div
                  key={(key = { key })}
                  data-aos="fade-right"
                  data-aos-duration={1000}
                >
                  <div className="is-flex my-4">
                    <Title level={2} className="m-0">
                      {key + 1}
                    </Title>
                    <Title level={2} className="m-0 mr-3">
                      .
                    </Title>
                    <div>
                      <Title level={3} className="m-0">
                        {service.title}
                      </Title>
                      <Title
                        level={5}
                        className="m-0 mt-3 pl-1 has-text-weight-normal has-text-justified"
                      >
                        {service.description}
                      </Title>
                    </div>
                  </div>
                  {key !== active.services.length - 1 && (
                    <div style={{ width: 150 }}>
                      <Divider />
                    </div>
                  )}
                </div>
              ))}
            </Col>
            <Col xs={0} lg={1}></Col>
            {lg ? (
              <Col span={7} className="p-3">
                <Title
                  data-aos="fade-up"
                  data-aos-duration={1000}
                  level={1}
                  className="m-0"
                  style={{ fontSize: 50 }}
                >
                  Simplify Your
                  <br />
                  {active.title}
                </Title>
                <div
                  style={{ width: 100 }}
                  data-aos="zoom-in"
                  data-aos-duration={1000}
                >
                  <Divider className="has-background-grey mt-2 mb-5" />
                </div>
                <Title
                  data-aos="fade-right"
                  data-aos-duration={1000}
                  level={4}
                  className="mt-0 mb-4 has-text-weight-normal "
                >
                  We use the latest technology to streamline the process,
                  ensuring accuracy and timelines every step of the way.
                </Title>
                <Button
                  data-aos="zoom-out"
                  data-aos-duration={1000}
                  type="primary"
                  className="my-5"
                  onClick={() => navigate(URLS.contact)}
                  size="large"
                >
                  Get a Callback
                </Button>
              </Col>
            ) : null}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetail;
