import { Card, Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import { map } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import URLS from "../../constants/nav";
import { Helmet } from "react-helmet";

const services = [
  {
    key: URLS.serviceBookKeeping,
    title: "Bookkeeping",
    image: "/bookkeeping.jpg",
  },
  {
    key: URLS.serviceManagement,
    title: "Management Account",
    image: "/management-accounting.jpg",
  },
  { key: URLS.servicePayroll, title: "Payroll", image: "/payroll.jpg" },
  {
    key: URLS.serviceOutsource,
    title: "Outsource Audit",
    image: "/pre-audit.jpg",
  },
];

const Services = () => {
  return (
    <div>
      <Helmet>
        <title>
          Services | La Camptabilite | Bookkeeping | Management Accounting |
          Payroll Service | Outsourced Audit Services | United States | United
          Kingdom | Europe | Middle East
        </title>
        <meta
          name="description"
          content="La Comptabilité offers comprehensive financial services worldwide, including management accounting, bookkeeping, payroll, and outsourced audit services. Our experienced team provides accurate, timely, and reliable financial information to help you make informed business decisions. Trust us to handle your financial needs so you can focus on growing your business. Contact us today to learn more about how we can help you achieve your financial goals."
        />
        <meta
          property="og:description"
          content="La Comptabilité offers comprehensive financial services worldwide, including management accounting, bookkeeping, payroll, and outsourced audit services. Our experienced team provides accurate, timely, and reliable financial information to help you make informed business decisions. Trust us to handle your financial needs so you can focus on growing your business. Contact us today to learn more about how we can help you achieve your financial goals."
        />
        <meta
          property="og:title"
          content="Services | La Camptabilite | Bookkeeping | Management Accounting | Payroll
      Service | Outsourced Audit Services | United States | United Kingdom |
      Europe | Middle East"
        />
      </Helmet>
      <figure className="image">
        <img src="/services-splash.jpg" alt="404 - Not Found" />
      </figure>
      <div className="container my-6 px-3">
        <div data-aos="fade-up" data-aos-duration={500}>
          <Title level={2} className="has-text-centered m-0 has-text-blue1">
            We offer a full range of Accounting
          </Title>
          <Title level={2} className="has-text-centered m-0 has-text-blue1">
            services for your business.
          </Title>
        </div>
        <Row className="mt-4">
          {map(services, (service) => (
            <Col xs={24} md={12} xl={6} key={service.key} className="p-3">
              <div
                data-aos="fade-up"
                data-aos-duration={500}
                style={{ height: "100%" }}
              >
                <Link to={service.key} style={{ height: "100%" }}>
                  <Card
                    className="card service-card"
                    style={{ height: "100%" }}
                  >
                    <figure className="image">
                      <img src={service.image} alt="404 - Not Found" />
                    </figure>
                    <Title level={4} className="has-text-centered mt-3 mb-0">
                      {service.title}
                    </Title>
                  </Card>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Services;
