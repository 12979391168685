import { uiRedux } from "../constants/redux";

const state = {
  Scroll: window.scrollY,
  Width: window.innerWidth,
  Height: window.innerHeight,
  Header: 0,
  Footer: 0,
  Scrolled: false,
};

const uiReducer = (initialState = state, action) => {
  const { data, type } = action;
  switch (type) {
    case uiRedux.updateWidth:
      return { ...initialState, Width: data };
    case uiRedux.updateHeight:
      return { ...initialState, Height: data };
    case uiRedux.updateScroll:
      return { ...initialState, Scroll: data };
    case uiRedux.updateHeader:
      return { ...initialState, Header: data };
    case uiRedux.updateFooter:
      return { ...initialState, Footer: data };
    case uiRedux.updateScrolled:
      return { ...initialState, Scrolled: data };
    default:
      return initialState;
  }
};

export default uiReducer;
