import React, { Fragment, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateHeader } from "../../actions/ui";
import { get, map } from "lodash";
import { Button } from "antd";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import URLS from "../../constants/nav";
import { Link, useNavigate } from "react-router-dom";

const navbar = [
  { url: URLS.home, label: "Home" },
  {
    url: URLS.services,
    label: "Services",
    children: [
      { url: URLS.serviceBookKeeping, label: "Bookkeeping" },
      { url: URLS.serviceManagement, label: "Management Accounting" },
      { url: URLS.servicePayroll, label: "Payroll" },
      { url: URLS.serviceOutsource, label: "Outsourced Audit Service" },
    ],
  },
  { url: URLS.about, label: "About" },
  { url: URLS.contact, label: "Contact" },
];

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <nav className={`navbar`} role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <figure style={{ aspectRatio: "4/1", width: 200 }}>
          <img
            src="/landscape.png"
            alt="404 - Not found"
            onClick={() => {
              navigate(URLS.home);
            }}
          />
        </figure>

        <Button
          type="text"
          className="is-hidden-desktop"
          style={{ marginLeft: "auto", marginRight: 5, alignSelf: "center" }}
          icon={open ? <CloseOutlined /> : <MenuOutlined />}
          onClick={() => {
            setOpen(!open);
          }}
        />
      </div>

      <div
        id="navbarBasicExample"
        className={`navbar-menu ${open ? "is-active" : ""}`}
      >
        <div className="navbar-start" style={{ marginLeft: "auto" }}>
          {map(navbar, (nav) => (
            <Fragment key={nav.url}>
              {nav.children && nav.children.length > 0 ? (
                <div className="navbar-item has-dropdown is-hoverable">
                  <Link
                    onClick={() => setOpen(false)}
                    to={nav.url}
                    className="navbar-link"
                  >
                    {nav.label}
                  </Link>

                  <div className="navbar-dropdown">
                    {map(nav.children, (child) => (
                      <Link
                        onClick={() => setOpen(false)}
                        key={child.url}
                        to={child.url}
                        className="navbar-item"
                      >
                        {child.label}
                      </Link>
                    ))}
                  </div>
                </div>
              ) : (
                <Link
                  onClick={() => setOpen(false)}
                  to={nav.url}
                  className="navbar-item"
                >
                  {nav.label}
                </Link>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </nav>
  );
};

const Header = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const Scrolled = useSelector((s) => s.ui.Scrolled);

  useLayoutEffect(
    () => {
      dispatch(updateHeader(get(ref, "current.clientHeight", 0)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref]
  );
  return (
    <div
      className={`header ${Scrolled ? "header-scrolled" : "header-simple"}`}
      ref={ref}
    >
      <Navbar />
    </div>
  );
};

export default Header;
