import React, { useEffect } from "react";
import URLS from "./constants/nav";
import Home from "./components/home";
import Header from "./components/header";
import { Route, Routes, useLocation } from "react-router-dom";
import { map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  updateHeight,
  updateScroll,
  updateScrolled,
  updateWidth,
} from "./actions/ui";
import Aos from "aos";
import "bulma/css/bulma.css";
import "aos/dist/aos.css";
import NotFound from "./components/extra/404";
import "./assets/css/main.css";
import Footer from "./components/footer";
import About from "./components/about";
import Contact from "./components/contact";
import Services from "./components/services";
import ServiceDetail from "./components/service-detail";

const routes = [
  { url: URLS.home, element: <Home /> },
  { url: URLS.about, element: <About /> },
  { url: URLS.contact, element: <Contact /> },
  { url: URLS.services, element: <Services /> },
  { url: `${URLS.service}/:service`, element: <ServiceDetail /> },
  { url: "*", element: <NotFound /> },
];

const Main = () => {
  const dispatch = useDispatch();
  const HeaderSize = useSelector((s) => s.ui.Header);
  const FooterSize = useSelector((s) => s.ui.Footer);
  const Scroll = useSelector((s) => s.ui.Scroll);
  const { pathname: path } = useLocation();

  useEffect(
    () => {
      if (Scroll > 100) dispatch(updateScrolled(true));
      else dispatch(updateScrolled(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Scroll]
  );

  useEffect(
    () => {
      window.addEventListener("resize", () => {
        dispatch(updateWidth());
        dispatch(updateHeight());
      });
      window.addEventListener("scroll", () => {
        dispatch(updateScroll());
      });
      Aos.init({ offset: 200 });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [path]);

  return (
    <div>
      <Header />
      <div
        style={{
          minHeight: `calc(100vh - ${HeaderSize}px - ${FooterSize}px)`,
          marginTop: `${HeaderSize}px`,
        }}
      >
        <Routes>
          {map(routes, (route) => (
            <Route key={route.url} path={route.url} element={route.element} />
          ))}
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Main;
