import Title from "antd/es/typography/Title";
import React, { Fragment } from "react";
import { useLayout } from "../hooks/layout";
import Typewriter from "typewriter-effect";
import { Button, Card, Col, Divider, Row } from "antd";
import {
  AccountBookOutlined,
  BookOutlined,
  DollarCircleOutlined,
  OrderedListOutlined,
  PhoneOutlined,
  PieChartOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import URLS from "../../constants/nav";
import { map } from "lodash";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Helmet } from "react-helmet";

const services = [
  {
    title: "Payroll Processing ",
    key: "payroll-processing",
    description:
      "Our experienced team ensures accurate and timely processing of payroll, including tax withholding and reporting, direct deposit, and employee wage garnishments.",
    icon: DollarCircleOutlined,
  },
  {
    title: "Audit Support",
    key: "audit-support",
    description:
      "We provides comprehensive audit support to ensure that our clients are prepared and confident during the audit process.",
    icon: PieChartOutlined,
  },

  {
    title: "Income Tax Return",
    key: "income-tax-return",
    description:
      "Our experts are well-versed in the latest tax laws and regulations, ensuring that our clients receive the maximum tax benefits and avoid any penalties",
    icon: AccountBookOutlined,
  },
  {
    title: "Accounting & Bookkeeping",
    key: "accounting-bookkeeping",
    description:
      "We offer a range of services including accounts payable and receivable management, bank statement reconciliation, payroll processing, and financial statement preparation.",
    icon: BookOutlined,
  },
  {
    title: "BAS/IAS",
    key: "bas-ias",
    description:
      "Our team assists with the preparation and submission of GST returns, ensuring accuracy and timely filing.",
    icon: ApiOutlined,
  },
  {
    title: "Financial Modelling",
    key: "financial-modelling",
    description:
      "We work closely with our clients to ensure that our models align with their specific business needs and goals, and provide ongoing support to help them make the most of the insights gained from our models.",
    icon: OrderedListOutlined,
  },
];

const why = [
  {
    title: "Customer Support",
    key: "customer-support",
    description: "Presence in 15+ Countries.",
  },
  {
    title: "Backed By Experience",
    key: "experience",
    description: "Company is backed by ACCAs and CPAs and IT Professionals",
  },
  {
    title: "Robust Data Security",
    key: "security",
    description:
      "LLP is compliant with GDPR Rules of EU and certified with ISO 9001:2015 and ISO 27001:2015",
  },
];

const numbers = [
  {
    key: "experience",
    count: 35,
    title: "Years of Experience",
    description:
      "Having experience of 35 Years in the field of Accounting. With Robust knowledge base",
  },
  {
    key: "clients",
    count: 50,
    title: "Satisfied Clients",
    description:
      "From 2009 we have provided services to 5000+ clients in form of bookkeeping and accounting with 100% satisfaction Ratio.",
  },
  {
    key: "returns",
    count: 10000,
    title: "Tax Returns",
    description:
      "Company has done 10000+ Returns of Payroll/IAS/BAS, Sales/VAT/GST along with Individual and Corporate income taxes.",
  },
];

const Home = () => {
  const { mobile, tablet, pc } = useLayout();
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>
          Home | La Camptabilite | Bookkeeping | Management Accounting | Payroll
          Service | Outsourced Audit Services | United States | United Kingdom |
          Europe | Middle East
        </title>
        <meta
          name="description"
          content="La Comptabilité India Private Limited or LCIPL is part of La Comptabilité Lé Group into businesses of Full Service Financial, Legal and IT consulting, Pharmacy and Pharmaceuticals, Edcom and Exports. La Comptabilité India Private Limited is Multicore Full Service Financial and IT Consulting Services Organization with strong base of Professionalism and Expertise. LCIPL has expertise in Financial Offshore services includes Bookkeeping, Accounting, Audit, Tax and Financial Consulting and Full Service IT Consulting specializing in Artificial Intelligence like Robotics and Business process Automation. We have provided services to global clients and countries like Australia, Canada, European Union, Japan, India, Israel, United Kingdom, United States. We have special services for Gulf Cooperation Council in Finance, Legal and IT Consultation Services."
        />
        <meta
          property="og:description"
          content="La Comptabilité India Private Limited or LCIPL is part of La Comptabilité Lé Group into businesses of Full Service Financial, Legal and IT consulting, Pharmacy and Pharmaceuticals, Edcom and Exports. La Comptabilité India Private Limited is Multicore Full Service Financial and IT Consulting Services Organization with strong base of Professionalism and Expertise. LCIPL has expertise in Financial Offshore services includes Bookkeeping, Accounting, Audit, Tax and Financial Consulting and Full Service IT Consulting specializing in Artificial Intelligence like Robotics and Business process Automation. We have provided services to global clients and countries like Australia, Canada, European Union, Japan, India, Israel, United Kingdom, United States. We have special services for Gulf Cooperation Council in Finance, Legal and IT Consultation Services."
        />
        <meta
          property="og:title"
          content="Home | La Camptabilite | Bookkeeping | Management Accounting | Payroll
      Service | Outsourced Audit Services | United States | United Kingdom |
      Europe | Middle East"
        />
      </Helmet>
      <div
        className="splash"
        style={{
          backgroundImage: `url('/home-splash.jpg')`,
          height: mobile ? 250 : tablet ? 350 : pc ? 450 : 600,
        }}
      >
        <div className="splash-wrapper">
          <div>
            <Title
              data-aos="fade-down"
              data-aos-duartion={500}
              level={1}
              className="has-text-white has-text-centered"
            >
              <Typewriter
                options={{ loop: true, autoStart: true }}
                onInit={(type) => {
                  type
                    .typeString("Focus on Your Business")
                    .pauseFor(2500)
                    .deleteAll()
                    .typeString("Let us do the Accounting")
                    .pauseFor(2500)
                    .deleteAll()
                    .start();
                }}
              />
            </Title>
            <p
              data-aos="fade-up"
              data-aos-delay={500}
              data-aos-duartion={500}
              className="has-text-light has-text-centered"
            >
              La Camptabilite is the leading Bookkeeping & Accounting company.
            </p>
            <div className="has-text-centered mt-5">
              <Button
                data-aos="zoom-in"
                data-aos-delay={1000}
                data-aos-duartion={500}
                onClick={() => navigate(URLS.contact)}
                size="large"
                icon={<PhoneOutlined style={{ transform: "rotate(90deg)" }} />}
                type="primary"
              >
                Contact Us
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-6 px-2">
        <Title
          level={3}
          className="has-text-centered m-0"
          data-aos="fade-right"
          data-aos-delay={500}
          data-aos-duartion={500}
        >
          Welcome To{" "}
          <Typewriter
            options={{ loop: true, autoStart: true }}
            onInit={(type) => {
              type
                .typeString("La Comptabilité India Private Limited")
                .pauseFor(2500)
                .deleteAll()
                .start();
            }}
          />
        </Title>
        <div
          data-aos="fade-down"
          data-aos-delay={1000}
          data-aos-duartion={500}
          className="is-flex is-justify-content-center"
        >
          <div style={{ width: 150 }}>
            <Divider className="has-background-blue1 my-3" />
          </div>
        </div>
        <p
          className="has-text-justified mt-3"
          data-aos="fade-up"
          data-aos-delay={1000}
          data-aos-duartion={500}
        >
          La Comptabilité India Private Limited or LCIPL is part of La
          Comptabilité Lé Group into businesses of Full Service Financial, Legal
          and IT consulting, Pharmacy and Pharmaceuticals, Edcom and Exports. La
          Comptabilité India Private Limited is Multicore Full Service Financial
          and IT Consulting Services Organization with strong base of
          Professionalism and Expertise. LCIPL has expertise in Financial
          Offshore services includes Bookkeeping, Accounting, Audit, Tax and
          Financial Consulting and Full Service IT Consulting specializing in
          Artificial Intelligence like Robotics and Business process Automation.
          We have provided services to global clients and countries like
          Australia, Canada, European Union, Japan, India, Israel, United
          Kingdom, United States. We have special services for Gulf Cooperation
          Council in Finance, Legal and IT Consultation Services.
        </p>
      </div>
      <div className="container py-6 px-2">
        <div data-aos="fade-up" data-aos-duration={500}>
          <Title level={2} className="has-text-centered m-0 has-text-blue1">
            We offer a full range of Accounting
          </Title>
          <Title level={2} className="has-text-centered m-0 has-text-blue1">
            services for your business.
          </Title>
        </div>
        <Row className="mt-5">
          {map(services, (service) => (
            <Col xs={24} md={12} lg={8} key={service.key} className="p-3">
              <Card
                data-aos="fade-up"
                data-aos-duration={1000}
                className="card"
                style={{ height: "100%" }}
              >
                <Row>
                  <Col
                    span={3}
                    className="is-flex is-justify-content-flex-end pr-2"
                  >
                    <service.icon style={{ fontSize: "25px" }} />
                  </Col>
                  <Col span={21}>
                    <Title level={4}>{service.title}</Title>
                    <p className="has-text-justified">{service.description}</p>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <div className="container py-6 px-2">
        <Title
          level={2}
          className="has-text-centered has-text-blue1"
          data-aos="fade-up"
          data-aos-duration={1000}
        >
          What makes us different
        </Title>
        <Row>
          {map(why, (item) => (
            <Col key={item.key} xs={24} lg={8} className="p-3">
              <div data-aos="fade-up" data-aos-duration={1000}>
                <div className="is-flex is-justify-content-center">
                  <figure className="image">
                    <img alt="404 - Not Found" src={`/${item.key}.png`} />
                  </figure>
                </div>
                <Title className="has-text-centered" level={4}>
                  {item.title}
                </Title>
                <p className="has-text-centered">{item.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div className="container py-6 px-2">
        <Title
          level={2}
          className="has-text-centered has-text-blue1"
          data-aos="fade-up"
          data-aos-duration={1000}
        >
          Let the numbers speak
        </Title>
        <Row>
          {map(numbers, (number, index) => (
            <Fragment key={number.key}>
              <Col xs={24} lg={6} className="p-3">
                <div data-aos="fade-up" data-aos-duration={1000}>
                  <VisibilitySensor>
                    {({ isVisible }) => {
                      return (
                        <Title level={1} className="has-text-centered">
                          <CountUp
                            start={0}
                            end={number.count}
                            redraw={isVisible}
                          />
                          +
                        </Title>
                      );
                    }}
                  </VisibilitySensor>
                  <Title level={3} className="has-text-centered mt-0">
                    {number.title}
                  </Title>
                  <p className="has-text-centered">{number.description}</p>
                </div>
              </Col>
              {index !== numbers.length - 1 && (
                <>
                  <Col
                    xs={0}
                    lg={3}
                    className="is-flex is-justify-content-center"
                  >
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                  <Col xs={24} lg={0}>
                    <Divider />
                  </Col>
                </>
              )}
            </Fragment>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Home;
