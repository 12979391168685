import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useLayout = () => {
  const Width = useSelector((state) => state.ui.Width);
  const [mobile, setMobile] = useState(false);
  const [tablet, setTablet] = useState(false);
  const [pc, setPc] = useState(false);
  const [xlPc, setXlPc] = useState(false);

  useEffect(() => {
    if (Width <= 480) {
      setMobile(true);
      setTablet(false);
      setPc(false);
      setXlPc(false);
    } else if (Width <= 768) {
      setMobile(false);
      setTablet(true);
      setPc(false);
      setXlPc(false);
    } else if (Width <= 1400) {
      setMobile(false);
      setTablet(false);
      setPc(true);
      setXlPc(false);
    } else if (Width > 1400) {
      setMobile(false);
      setTablet(false);
      setPc(false);
      setXlPc(true);
    } else {
      setMobile(false);
      setTablet(false);
      setPc(false);
      setXlPc(false);
    }
  }, [Width]);

  return { mobile, tablet, pc, xlPc };
};
